
    import { IonList, IonItem, IonLabel, IonButton, IonIcon, IonAvatar, IonImg, IonRow, IonCol } from '@ionic/vue';
    import { store } from '../../store/store';
    import { chevronForwardSharp } from 'ionicons/icons';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { MemberModel } from '../../generated';
    import auth from 'authur';

    export default {
        name: 'Profile',
        mixins: [LanguageMixin],
        components: {
            IonList,
            IonItem,
            IonLabel,
            IonButton,
            IonIcon,
            IonAvatar,
            IonImg,
            IonRow,
            IonCol
        },
        setup() {
            return {
                chevronForwardSharp
            }
        },
        computed: {
            userName(): string | null {
                return localStorage.getItem('userName');
            },
            fboNummer(): string | null {
                return localStorage.getItem('fboNummer');
            },
            photo(): string | null {
                return localStorage.getItem('photo');
            },
            loggedIn(): boolean {
                return localStorage.getItem('loggedIn') == 'true';
            }
        },
        methods: {
            logout() {
                store.state.loggedInUser = new MemberModel();
                localStorage.removeItem('userName');
                localStorage.removeItem('fboNummer');
                localStorage.removeItem('photo');
                auth.signout();
                window.location.reload();
            }
        }
    }
